<template>
  <div class="row rest">
    <div class="rest aside-bg"></div>
    <ThinqProSnb class="left-container" />
    <article class="right-container">
      <router-view />
    </article>
    <div class="rest"></div>
  </div>
</template>
<script>
import ThinqProSnb from "./ThinqProSnb.vue";

export default {
  components: {
    ThinqProSnb,
  },
  mounted() {
    this.$store.commit("setScrollUpState", false);
  },
  destroyed() {
    this.$store.commit("setScrollUpState", true);
  },
};
</script>
